<template>
    <ScrollableContainer>
        <div class="register-venue bg-light text-primary">
            <div class="register-venue__content">
                <header class="register-venue__header">
                    <img src="@/assets/logo_primary.svg" />
                    <button
                        class="button button-close"
                        @click="$router.replace('/dashboard')"
                    ></button>
                </header>

                <form autocomplete="off" class="gb-form" @submit.prevent="handleSubmit">
                    <input style="display: none" />
                    <input type="password" style="display: none" />
                    <h2 class="font-weight-medium">Your venue page</h2>

                    <fieldset class="gb-form__fieldset gb-form__fieldset--centered">
                        <div class="register-venue__profile-photo">
                            <ImageUploader v-model="profileImageFile" profile>
                                <template #temp>
                                    <div class="register-venue__profile-photo-placeholder">
                                        <Badge label="Profile photo" color="beige" />
                                        <img src="@/assets/icons/photo_camera_icon.svg" />
                                    </div>
                                </template>
                            </ImageUploader>
                        </div>
                    </fieldset>
                    <fieldset class="gb-form__fieldset">
                        <div class="register-venue__cover-photo">
                            <ImageUploader v-model="coverImageFile">
                                <template #temp>
                                    <div class="register-venue__profile-photo-placeholder">
                                        <Badge label="Cover photo" color="beige" />
                                        <img src="@/assets/icons/photo_camera_icon.svg" />
                                    </div>
                                </template>
                            </ImageUploader>
                        </div>
                    </fieldset>

                    <fieldset class="gb-form__fieldset">
                        <label for="name"> Name </label>
                        <BaseInput
                            id="name"
                            v-model="currentState.name"
                            placeholder="venue name"
                            text-align="center"
                        />
                    </fieldset>

                    <fieldset class="gb-form__fieldset">
                        <label for="biography">Biography</label>
                        <Textarea
                            id="biography"
                            v-model="currentState.description"
                            placeholder="write something about the venue..."
                        ></Textarea>
                    </fieldset>

                    <fieldset class="gb-form__fieldset gb-form__fieldset--centered">
                        <p>Type of venue</p>
                        <div
                            v-for="(types, category, index) in categoryDto"
                            :key="category + index"
                        >
                            <h2>{{ category }}</h2>
                            <p
                                v-for="(type, index2) in types"
                                :key="type + index2"
                                class="capitalize"
                            >
                                {{ type.toLowerCase() }}
                            </p>
                        </div>
                        <Button
                            label="Add category"
                            size="small"
                            square
                            type="button"
                            @click="showCategoryPickerModal = true"
                        />
                        <Modal v-if="showCategoryPickerModal">
                            <VenueCategoryPicker
                                v-model="currentState.categories"
                                @cancel="showCategoryPickerModal = false"
                            />
                        </Modal>
                    </fieldset>

                    <fieldset class="gb-form__fieldset">
                        <label for="address">Address</label>
                        <AddressLocationSearch
                            id="address"
                            v-model="addressSearchData"
                            required
                            placeholder="Street address and number"
                        />
                    </fieldset>

                    <fieldset class="gb-form__fieldset gb-form__fieldset--col-2">
                        <legend class="gb-form--span-row">
                            Average price range for rental a day
                        </legend>
                        <BaseInput
                            v-model.number="currentState.priceMin"
                            type="number"
                            placeholder="from"
                            text-align="left"
                        />
                        <BaseInput
                            v-model.number="currentState.priceMax"
                            type="number"
                            placeholder="to"
                            text-align="left"
                        />
                    </fieldset>

                    <fieldset class="gb-form__fieldset gb-form__fieldset--col-2">
                        <legend class="gb-form--span-row">
                            Average price range for rental a day
                            <small>(weekends)</small>
                        </legend>
                        <BaseInput
                            v-model.number="currentState.priceMinWeekend"
                            type="number"
                            placeholder="from"
                            text-align="left"
                        />
                        <BaseInput
                            v-model.number="currentState.priceMaxWeekend"
                            type="number"
                            placeholder="to"
                            text-align="left"
                        />
                    </fieldset>

                    <fieldset class="gb-form__fieldset">
                        <label for="capacity">Capacity</label>
                        <BaseInput
                            v-model.number="currentState.capacity"
                            placeholder="maximum capacity"
                            text-align="center"
                        />
                    </fieldset>

                    <fieldset class="gb-form__fieldset">
                        <legend>Contact information</legend>
                        <BaseInput
                            v-model="currentState.email"
                            placeholder="email"
                            type="email"
                            text-align="center"
                        />
                        <BaseInput
                            v-model="currentState.phone"
                            placeholder="phone number"
                            text-align="center"
                        />
                        <BaseInput
                            v-model="currentState.web"
                            placeholder="http://yourwebsite.com"
                            text-align="center"
                        />
                    </fieldset>

                    <!-- <fieldset class="gb-form__fieldset">
                        <label>Social media</label>
                        <BaseInput placeholder="http://socialmedia.com" />
                        <Button
                            class="gb-form__fieldset--centered"
                            label="Add more"
                            square
                            size="small"
                        />
                    </fieldset> -->
                    <JumboButton
                        v-show="isChanged"
                        type="submit"
                        :label="
                            isLoadingCreateVenueMutation
                                ? 'Creating profile...'
                                : 'Submit and register'
                        "
                        :style="{ marginBottom: '-1px' }"
                        :disabled="isLoadingCreateVenueMutation"
                    />
                </form>
            </div>
        </div>
    </ScrollableContainer>
</template>

<script>
import JumboButton from '@/components/JumboButton'
import Badge from '@/components/Badge'
import Textarea from '@/components/Textarea'
import AddressLocationSearch from '@/components/auto-suggest/AddressLocationSearch'
import Modal from '@/components/modals/Modal'
import VenueCategoryPicker from '@/components/VenueCategoryPicker'
import ImageUploader from '@/components/ImageUploader'
import { CREATE_VENUE_PROFILE } from '@/graphql/mutations/user'
import { ME, MY_VENUE_PROFILE } from '@/graphql/queries/user'
import { toVenueCategoryDto } from '@/mappers/category'

import RegisterProfileMixin from './RegisterProfileMixin'
export default {
    name: 'RegisterVenue',
    components: {
        JumboButton,
        Badge,
        Textarea,
        AddressLocationSearch,
        Modal,
        VenueCategoryPicker,
        ImageUploader
    },
    mixins: [RegisterProfileMixin],
    inject: ['switchToVenueProfile'],
    data() {
        return {
            showCategoryPickerModal: false,
            showProfileClipperModal: false,
            showCoverClipperModal: false,
            addressSearchData: null,

            initialState: {},
            currentState: {},

            coverPictureUrl: '',
            profilePictureUrl: '',
            profilePictureTemp: null,
            coverPictureTemp: null,
            profileImageFile: null,
            coverImageFile: null,

            isLoadingCreateVenueMutation: false
        }
    },
    computed: {
        isChanged() {
            return this.isImageUploaded || this.isFormTouched
        },
        isImageUploaded() {
            return this.profilePictureTemp || this.coverPictureTemp
        },
        isFormTouched() {
            return JSON.stringify(this.initialState) !== JSON.stringify(this.currentState)
        },
        categoryDto() {
            return toVenueCategoryDto(this.currentState.categories || [])
        },
        payload() {
            const { email, phone, web } = this.currentState

            const location = this.addressSearchData
                ? {
                      lat: this.addressSearchData.geo.lat,
                      long: this.addressSearchData.geo.lng
                  }
                : null
            const address = this.addressSearchData
                ? {
                      street: this.addressSearchData.name,
                      zip: this.addressSearchData.zip,
                      city: this.addressSearchData.postal
                  }
                : null

            const contact =
                email || phone || web
                    ? {
                          email: this.currentState.email,
                          phone: this.currentState.phone,
                          web: this.currentState.web
                      }
                    : null

            const {
                priceMin,
                priceMax,
                priceMinWeekend,
                priceMaxWeekend,
                description,
                capacity
            } = this.currentState
            const catDto = this.categoryDto
            return {
                name: this.currentState.name,
                description: description ? description : undefined,
                priceMin: priceMin ? priceMin : undefined,
                priceMax: priceMax ? priceMax : undefined,
                priceMinWeekend: priceMinWeekend ? priceMinWeekend : undefined,
                priceMaxWeekend: priceMaxWeekend ? priceMaxWeekend : undefined,
                categories: Object.keys(catDto).length ? catDto : undefined,
                location: location ? location : undefined,
                address: address ? address : undefined,
                contact: contact ? contact : undefined,
                capacity: capacity ? capacity : undefined
            }
        }
    },
    mounted() {
        this.initialState = {
            name: '',
            description: '',
            street: '',
            zip: '',
            city: '',
            priceMin: '',
            priceMax: '',
            priceMinWeekend: '',
            priceMaxWeekend: '',
            capacity: '',
            email: '',
            phone: '',
            web: '',
            categories: []
        }
        this.currentState = JSON.parse(JSON.stringify(this.initialState))
    },
    methods: {
        uploadImages(mediaId) {
            if (this.coverImageFile && this.profileImageFile) {
                return Promise.all([
                    this.uploadImage(mediaId, 'profile', this.profileImageFile),
                    this.uploadImage(mediaId, 'cover', this.coverImageFile)
                ])
            } else if (this.coverImageFile) {
                return this.uploadImage(mediaId, 'cover', this.coverImageFile)
            } else if (this.profileImageFile) {
                return this.uploadImage(mediaId, 'profile', this.profileImageFile)
            }
        },
        async handleSubmit() {
            try {
                console.log('Submitting')
                this.isLoadingCreateVenueMutation = true

                console.log('Creating new venue profile...')
                const result = await this.$apollo.mutate({
                    mutation: CREATE_VENUE_PROFILE,
                    variables: {
                        input: {
                            data: this.payload
                        }
                    },
                    refetchQueries: [{ query: ME }, { query: MY_VENUE_PROFILE }]
                })

                const { type, venue, errors } = result.data.createVenueProfile

                if (type == 'ResponseError') {
                    errors.forEach((error) => console.log(error))
                }

                if (type === 'createVenuePayload') {
                    // Fetch my venue profile manually
                    await this.$apollo.query({
                        query: MY_VENUE_PROFILE,
                        fetchPolicy: 'network-only'
                    })
                    this.switchToVenueProfile()
                    // Upload images if any
                    await this.$_uploadImages(venue.media.id, [
                        { type: 'profile', file: this.profileImageFile },
                        { type: 'cover', file: this.coverImageFile }
                    ])
                    console.log('Success!')
                    this.$router.replace('/dashboard')
                }
            } catch (error) {
                console.error('Error when creating venue profile')
                console.error({ error })
            } finally {
                this.isLoadingCreateVenueMutation = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.register-venue {
    text-align: center;
    padding-bottom: 150px;

    &__header {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr auto 1fr;
        height: 90px;
        padding: 1rem;
        :nth-child(1) {
            grid-column-start: 2;
            width: 60px;
        }
        :last-child {
            justify-self: end;
        }
    }

    &__zip-city {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        column-gap: 1rem;
        & > :first-child {
            grid-column: 1 / 5;
        }
        & > :nth-child(2) {
            grid-column: 5 / 13;
        }
    }

    &__profile-photo {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: #fff;
        row-gap: 20px;
        overflow: hidden;
    }

    &__cover-photo {
        width: 100%;
        height: 160px;
        background: #fff;
    }

    &__profile-photo-placeholder {
        display: grid;
        place-items: center;
        place-content: center;
        row-gap: 1rem;
        height: 100%;
        width: 100%;
        label {
            display: block;
        }
    }
}
@media only screen and (min-width: 768px) {
    /* For desktop: */
    .register-venue {
        &__content {
            max-width: 768px;
            margin: auto;
        }
    }
}
</style>
