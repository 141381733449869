import gql from 'graphql-tag'

export const ARTIST_CATEGORIES = gql`
    query ArtistCategories {
        artistCategories
    }
`

export const VENUE_CATEGORIES = gql`
    query VenueCategories {
        venueCategories
    }
`
