export default {
    inject: ['uploadImage'],
    methods: {
        $_uploadImages(mediaId, images) {
            // Upload profile and / or image file (or none)
            return Promise.all(
                images
                    .filter(({ file }) => file)
                    .map((image) => this.uploadImage(mediaId, image.type, image.file))
            )
        }
    }
}
