<template>
    <AutosuggestQuery
        :gql-query="query"
        v-bind="$attrs"
        :render-suggestion="(suggestion) => suggestion.item.addressname"
        :get-suggestion="(suggestion) => suggestion.item.addressname"
        @input="$emit('input', $event)"
    />
</template>

<script>
import gql from 'graphql-tag'
import AutosuggestQuery from '@/components/auto-suggest/AutosuggestQuery'

export default {
    name: 'AddressLocation',
    components: {
        AutosuggestQuery
    },
    emits: ['input'],
    computed: {
        query() {
            return {
                name: 'addressLocation',
                query: gql`
                    query AddressLocation($q: String!) {
                        addressLocation(q: $q)
                    }
                `
            }
        }
    }
}
</script>
