<template>
    <div class="category-picker bg-light text-primary">
        <div class="category-picker__header">
            <h3>Add your venue category</h3>
            <button class="button button-close" @click="$emit('cancel')"></button>
        </div>
        <div class="category-picker__list">
            <div
                v-for="(category, index) in venueCategories"
                :key="category + index"
                class="category-picker__list-item"
            >
                <CollapsiblePanel
                    :is-collapsed-initial="!selected.some((item) => item.category === category.id)"
                >
                    <template #header="{ toggle, isCollapsed }">
                        <div class="category-picker__input-row" @click="toggle">
                            <label>
                                {{ category.name }}
                            </label>
                            <img v-if="isCollapsed" src="@/assets/icons/chevron_down_icon.svg" />
                            <img v-else src="@/assets/icons/chevron_up_icon.svg" />
                        </div>
                    </template>
                    <template #content>
                        <div class="category-picker__list">
                            <div
                                v-for="(genre, index2) in category.genres"
                                :key="genre.id + index2"
                                class="category-picker__list-item"
                            >
                                <div class="category-picker__input-row">
                                    <label>
                                        {{ genre.name }}
                                    </label>
                                    <input
                                        v-model="selected"
                                        type="checkbox"
                                        :value="{
                                            category: category.id,
                                            value: genre.value
                                        }"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                </CollapsiblePanel>
            </div>
        </div>
        <Button
            v-if="changed"
            label="Save"
            big-square-fixed-bottom
            primary
            type="button"
            @click="confirm"
        />
    </div>
</template>

<script>
import CollapsiblePanel from '@/components/CollapsiblePanel'
import Button from '@/components/Button'
import { VENUE_CATEGORIES } from '@/graphql/queries/categories.js'
export default {
    name: 'VenueCategoryPicker',
    components: {
        CollapsiblePanel,
        Button
    },
    apollo: {
        venueCategories: {
            query: VENUE_CATEGORIES
        }
    },
    emits: ['cancel', 'input'],
    data() {
        return {
            selected: this.$attrs.value,
            venueCategories: {}
        }
    },
    computed: {
        sorted() {
            return this.sort(this.selected)
        },
        changed() {
            return JSON.stringify(this.sort(this.$attrs.value)) !== JSON.stringify(this.sorted)
        }
    },
    methods: {
        confirm() {
            this.$emit('input', this.selected)
            this.$emit('cancel')
        },
        sort(items) {
            return Object.values(items).sort(function(a, b) {
                let c = a.category.localeCompare(b.category)

                if (c === 0) {
                    return a.value.localeCompare(b.value)
                } else {
                    return c
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.category-picker {
    min-height: 100vh;
    width: 100vw;
    padding-left: 1rem;
    padding-right: 1rem;

    &__header {
        padding-top: 4rem;
        padding-bottom: 4rem;
        display: grid;
        grid-template-columns: auto auto;
        justify-items: end;
        :first-child {
            justify-self: start;
        }
    }

    &__list {
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: grid;
        row-gap: 0.5rem;
        width: 100%;
    }
    &__list-title {
        text-align: left;
    }
    &__input-row {
        display: flex;
        justify-content: space-between;
        label {
            align-self: center;
        }

        input[type='checkbox'] {
            height: 29px;
            width: 29px;
            margin: 0;
            appearance: none;
            padding: 0;
            border-radius: 2px;
            border: 0;
            background: white;
            box-shadow: 0 0 1px 1px rgba($color: #000000, $alpha: 0.5);
            outline: 0;
        }

        input[type='checkbox']:checked {
            background: url('~@/assets/checkbox_checked.svg');
            box-shadow: 0 0 0 0 white;
        }
    }
    &__list-item {
        width: 100%;
        display: grid;
        row-gap: 0.5rem;
    }
}
@media only screen and (min-width: 768px) {
    /* For desktop: */
    .category-picker {
        max-width: 400px;
        height: 90%;
        margin: auto;
    }
}
</style>
