<template>
    <div v-bind="$attrs">
        <slot name="header" v-bind="{ toggle, isCollapsed }"></slot>
        <slot v-if="!isCollapsed" name="content"></slot>
    </div>
</template>

<script>
export default {
    name: 'CollapsiblePanel',
    props: {
        isCollapsedInitial: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isCollapsed: this.isCollapsedInitial
        }
    },

    methods: {
        toggle() {
            this.isCollapsed = !this.isCollapsed
        }
    }
}
</script>
