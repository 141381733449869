export const toViewModel = (dto) => {
    return Object.entries(dto)
        .map(([category, types]) => {
            return Object.entries(types)
                .map(([type, genres]) => {
                    return genres.map((genre) => ({
                        category,
                        type,
                        value: genre
                    }))
                })
                .flatMap((x) => x)
        })
        .flatMap((x) => x)
}
export const toDto = (vm = []) => {
    let dto = {}
    vm.forEach(({ category, value, type }) => {
        let temp = dto[category] || {}
        let typeArr = temp[type] || []
        dto[category] = {
            ...temp,
            [type]: [...typeArr, value]
        }
    })
    return dto
}

export const toVenueCategoryViewModel = (dto) => {
    return Object.entries(dto)
        .map(([category, types]) => {
            return types.map((type) => ({ category, value: type }))
        })
        .flatMap((x) => x)
}

export const toVenueCategoryDto = (vm) => {
    let dto = {}

    vm.forEach(({ category, value }) => {
        let o = dto[category] || []
        dto[category] = [...o, value]
    })
    return dto
}
